<template>
  <div class="search">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="搜索" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <!-- 搜索区域 -->
    <div class="search-box">
      <img src="../../assets/images/search.png" alt="" />
      <input class="search_input" type="text" placeholder="搜索课程和相关视频" @keydown.enter="getVideoList" v-model="search" />
    </div>
    <!-- 搜索区域 -->
    <hr />
    <div class="list-box">
      <router-link :to="'/VideoItem/' + item.id + '/0'" class="item" v-for="item in videoList" :key="item.id">
        <div class="item-left">
          <img :src="item.cover" alt="" />
        </div>
        <div class="item-right">
          <div class="title">{{ item.title | textOverflow(15) }}</div>
          <span>{{ item.desc }}</span>
          <div class="bottom">
            <span class="time">时长：{{ item.duration | formatDate }}</span>
            <span class="study">{{ item.study_count }}人学习</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { getVideoList } from '@/api/Index/Search'
export default {
  name: 'Search',
  data() {
    return {
      // 搜素关键字
      search: '',
      // 课程列表
      videoList: []
    }
  },
  mounted() {},
  methods: {
    // 获取视频列表
    async getVideoList() {
      const { data: res } = await getVideoList(this.search)
      this.videoList = res.data
      console.log(res)
    }
  }
}
</script>
<style lang="less" scoped>
.search-box {
  background-color: #f9f9f9;
  height: 106px;
  box-sizing: border-box;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  img {
    position: absolute;
    top: 30px;
    left: 235px;
    width: 26px;
    height: 26px;
  }
  .search_input {
    box-shadow: 0px 3px 10px 0px rgba(1, 1, 1, 0.19);
    border: 0;
    background-color: #ffffff;
    box-sizing: border-box;
    padding-left: 188px;
    width: 582px;
    height: 61px;
    background: #ffffff;
    border-radius: 30px;
    font-size: 26px;
    font-weight: 400;
    color: #333333;
  }
}
hr {
  width: 710px;
  height: 1px;
  background: #a6a6a6;
  margin: 0 auto;
}
.list-box {
  padding-left: 20px;
  padding-top: 48px;
  padding-bottom: 30px;
  .item {
    display: flex;
    height: 170px;
    margin-bottom: 40px;
    .item-left {
      width: 120px;
      height: 170px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-right {
      flex: 1;
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        height: 26px;
        font-size: 28px;
        font-weight: 500;
        color: #333333;
      }
      > span {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
      }
      .bottom {
        .time,
        .study {
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: #666666;
        }
        .study {
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
