<template>
  <router-link :to="'/VideoItem/' + classInfo.id + '/0'" class="course-item">
    <div class="item_left">
      <img class="cover" :src="classInfo.cover" alt="" v-lazy="classInfo.cover" />
    </div>
    <div class="item_right">
      <div class="title">{{ classInfo.title | textOverflow(13) }}</div>
      <div class="describe">{{ classInfo.desc | textOverflow(25) }}</div>
      <div class="row">
        <div class="certificate" v-if="classInfo.exam_plan_id > 0">课程证书</div>
        <span>{{ classInfo.study_count + '人已学习  ' + classInfo.unit_count + '课' }}</span>
        <fragment v-if="isShowStatus">
          <div class="status learning_status" v-if="classInfo.exam_status === 1">学习中</div>
          <div class="status" v-else-if="classInfo.exam_status === 2">已完成</div>
          <div class="status not_through" v-else-if="classInfo.exam_status === 3">未通过考试</div>
          <div class="status not_through" v-else>未完成</div>
        </fragment>
        <div class="status learning_status" v-if="isBuy">已购买</div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  name: 'CourseItem',
  props: {
    // 课程数据
    classInfo: {
      type: Object,
      required: true
    },
    // 是否显示状态
    isShowStatus: {
      type: Boolean
    },
    // 是否已购买
    isBuy: {
      type: Boolean
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.course-item {
  margin: 0 auto 30px;
  width: 710px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  box-sizing: border-box;
  padding-top: 22px;
  padding-left: 10px;
  .item_left {
    width: 160px;
    height: 160px;
    .cover {
      width: 160px;
      height: 160px;
      border-radius: 10px;
    }
  }
  .item_right {
    height: 160px;
    box-sizing: border-box;
    padding-left: 23px;
    padding-right: 35px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
    .describe {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
    }
    .row {
      display: flex;
      align-items: center;
      width: 480px;
      .certificate {
        height: 29px;
        line-height: 29px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        padding: 5px;
        background: #efedf9;
        border-radius: 10px;
        margin-right: 22px;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        color: #999999;
      }
      .status {
        margin-left: auto;
        font-size: 20px;
        font-weight: bold;
        color: #1571d8;
      }
      .learning_status {
        color: #32c635;
      }
      .not_through {
        color: #af1a01;
      }
    }
  }
}
</style>
