<template>
  <div class="open_vip">
    <div class="vip_top">
      <div class="title">创业指导师职业技能等级认定</div>
      <img src="../assets/images/vip.png" alt="" />
      <!-- <router-link to="/BuyVip" class="vip_btn">去开通></router-link> -->
    </div>
    <div class="vip_bottom">
      <div class="title">阐明创业孕育发展内在规律，提高创业教学指导水平。</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OpenVip',
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
// 开通会员
.open_vip {
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 37px;
  width: 710px;
  height: 140px;
  background: linear-gradient(90deg, #fe8b4c, #fedb97);
  border-radius: 20px;
  padding-top: 29px;
  .vip_top {
    display: flex;
    .title {
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
    }
    img {
      margin-left: 4px;
      width: 27px;
      height: 30px;
    }
    .vip_btn {
      text-align: center;
      width: 107px;
      height: 34px;
      line-height: 34px;
      background: #ffffff;
      border-radius: 17px;
      font-size: 18px;
      font-weight: bold;
      color: #fd7924;
      margin-left: auto;
    }
  }
  .vip_bottom {
    margin-top: 22px;
    .title {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
