<template>
  <div class="index">
    <!-- 头部区域 -->
    <div class="header">
      <!-- 搜索区域 -->
      <div class="search" >
        <img src="../../assets/images/search.png" alt="" />
        <input class="search_input" type="text" placeholder="搜索课程和相关视频" @click="$router.push('/Search')" />
      </div>
      <!-- 搜索区域 -->

      <!-- 轮播图区域 -->
      <van-swipe class="my-swipe" indicator-color="white" autoplay="5000">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <img :src="item.image" alt="" v-lazy="item.image" />
        </van-swipe-item>
      </van-swipe>
      <!-- 轮播图区域 -->
    </div>
    <!-- 头部区域 -->

    <div class="wrap">
      <!-- 最新通知区域 -->
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="item in newsList" :key="item.id">{{ item.name }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
      <!-- 最新通知区域 -->

      <!-- 首页图标区域 -->
      <div class="index_card" v-if="cateList.total > 0">
        <router-link :to="item.url" class="item" v-for="item in cateList.data" :key="item.id">
          <img :src="item.image" alt="" v-lazy="item.image" />
          <span class="title" v-text="item.title"></span>
        </router-link>
      </div>
      <!-- 首页图标区域 -->

      <!-- 广告区域 -->

      <!-- 广告区域 -->

      <!-- 创业指导师专项区域 -->
      <div class="tutor">
        <!-- <div class="title" >创业指导师专项</div> -->
        <div class="top" >
          <div class="item" v-for="item in bannerVideoList" :key="item.id">
            <router-link :to="'/VideoItem/' + item.id + '/0'" class="course-item">
              <img :src="item.thumb" alt="" />
              <div class="title">{{ item.title }}</div>
            </router-link>
          </div>
        </div>
        <router-link :to="'/VideoItem/' + item.id + '/0'" class="item" v-for="item in teacherList" :key="item.id">
          <div class="item-left">
            <img :src="item.thumb" alt="" />
          </div>
          <div class="item-right">
            <div class="title">{{ item.title }}</div>
            <span>{{ item.desc }}</span>
            <div class="bottom">
              <span class="time">时长：{{ item.duration | formatDate }}</span>
              <span class="study">{{ item.study_count }}人学习</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 创业指导师专项区域 -->
      <div v-if="false">
        <!-- 视频推荐区域 -->
        <video-recommend class="video-recommend" v-for="item in videoRecommendList" :key="item.id" :title="item.subname" :list="item.lists.data"></video-recommend>
        <!-- 视频推荐区域 -->
      </div>
      <div class="advertising" v-if="advInfo.total > 0">
        <img :src="advInfo.data[0].image" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { getBannerList, getIndexCate, getAdv, postUserCode, getBannerVideoList, getNewsList } from '@/api/Index/index'
import videoRecommend from './components/videoRecommend.vue'
import { getItem, setItem } from '@/utils/localStorage'
export default {
  name: 'Index',
  components: { videoRecommend },
  data() {
    return {
      // 轮播图列表
      bannerList: [],
      // 公告列表
      newsList: [],
      // 图标列表
      cateList: {},
      // 广告数据
      advInfo: {},
      // banner视频推荐
      bannerVideoList: [],
      // 老师推荐
      teacherList: [],
      // 推荐视频分类
      videoRecommendList: [],
      // token
      token: getItem('token') || false
    }
  },
  mounted() {
    if (!this.token) {
      // 获取用户code
      this.getUserCode()
    }
    // 获取轮播图数据
    this.getBannerList()
    // 获取公告
    this.getNewsList()
    // 获取首页图标数据
    this.getIndexCate()
    // 获取广告数据
    this.getAdv()
    // 获取banner推荐
    this.getBannerVideoList()
    // 获取老师列表
    this.getTeacherList()
    // 获取课程推荐分类
    this.getVideoRecommend()
  },
  methods: {
    // 获取用户code
    async getUserCode() {
      const code = window.location.href
        .split('?')[1]
        .split('&')[0]
        .split('=')[1]
      const res = await postUserCode(code)
      if (res.code === 500) {
        return this.$router.push('/Choose')
      } else {
        setItem('token', res.data.token)
      }
    },
    // 获取轮播图数据
    async getBannerList() {
      const { data } = await getBannerList()
      this.bannerList = data.data
    },
    // 获取公告列表
    async getNewsList() {
      const { data } = await getNewsList(10)
      this.newsList = data.rows
    },
    // 获取首页图标数据
    async getIndexCate() {
      const { data } = await getIndexCate()
      this.cateList = data
    },
    // 获取广告数据
    async getAdv() {
      const { data } = await getAdv()
      this.advInfo = data
    },
    // 获取banner推荐
    async getBannerVideoList() {
      const { data } = await getBannerVideoList('hot')
      this.bannerVideoList = data.data
    },
    // 获取首页推荐
    async getTeacherList() {
      const { data } = await getBannerVideoList('index_recomment')
      this.teacherList = data.data
    },
    // 获取课程推荐分类
    async getVideoRecommend() {
      const { data } = await getBannerVideoList('index')
      this.videoRecommendList = data
    }
  }
}
</script>
<style lang="less" scoped>
.index {
  padding-bottom: 100px;
  background-color: #f9f9f9;
  .header {
    background-image: url(../../assets/images/header_bg.png);
    background-size: 750px;
    background-repeat: no-repeat;
    // 搜索区域
    .search {
      height: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        position: absolute;
        left: 235px;
        width: 26px;
        height: 26px;
      }
      .search_input {
        border: 0;
        background-color: #ffffff;
        box-sizing: border-box;
        padding-left: 188px;
        width: 582px;
        height: 61px;
        background: #ffffff;
        border-radius: 30px;
        font-size: 26px;
        font-weight: 400;
        color: #333333;
      }
    }
    // 轮播图
    .my-swipe {
      border-radius: 30px;
      margin: 0 auto;
      width: 717px;
      height: 251px;
      .van-swipe-item img {
        border-radius: 30px;
        width: 717px;
        height: 251px;
      }
      /deep/.van-swipe__indicators .van-swipe__indicator--active {
        width: 30px;
        border-radius: 30px;
      }
    }
  }
  .wrap {
    margin: 0 auto;
    width: 717px;
    // 通知公告
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
    // 图标区域
    .index_card {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      .item {
        display: block;
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }
        .title {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    // 广告区域
    .advertising {
      margin-top: 20px;
      height: auto;
      img {
        border: 0;
        border-radius: 50px;
        width: 100%;
        height: 100%;
      }
    }
    // 视频推荐
    .video-recommend {
      margin-top: 30px;
    }
    // 创业指导师区域
    .tutor {
      // margin-top: 30px;
      background-color: #fff;
      padding-top: 20px;
      > .title {
        height: 31px;
        line-height: 31px;
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }
      > .top {
        margin-top: 32px;
        display: flex;
        border-bottom: 1px solid #a6a6a6;
        padding-bottom: 40px;
        margin-bottom: 47px;
        .item {
          flex: 1;
          &:first-child {
            margin-right: 23px;
          }
          img {
            width: 340px;
            height: 230px;
            border-radius: 30px;
          }
          .title {
            font-size: 26px;
            line-height: 46px;
            font-weight: 500;
            color: #333333;
          }
        }
      }
      > .item {
        display: flex;
        height: 170px;
        margin-bottom: 40px;
        .item-left {
          width: 170px;
          height: 170px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-right {
          flex: 1;
          margin-left: 18px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            height: 26px;
            font-size: 28px;
            font-weight: 500;
            color: #333333;
          }
          > span {
            font-size: 24px;
            font-weight: 400;
            color: #666666;
          }
          .bottom {
            .time,
            .study {
              height: 19px;
              font-size: 20px;
              font-weight: 400;
              color: #666666;
            }
            .study {
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
