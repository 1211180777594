import request from '@/utils/request.js'
// 获取视频详情
export const getVideoInfo = id => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/info',
    data: {
      id
    }
  })
}
// 获取视频播放地址
export const getVideoUrl = id => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/content',
    data: {
      id
    }
  })
}
// 收藏/取消视频
export const editVideoFav = id => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/fav',
    data: {
      id
    }
  })
}
// 视频评论
export const postPingLun = data => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.comment/save',
    data: {
      course_id: data.course_id,
      pid: 0,
      content: data.content
    }
  })
}
// 设置课程观看完成
export const setVideoFinish = data => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/finish_course',
    data: {
      id: data.id,
      seconds: data.seconds,
      duration: data.duration
    }
  })
}
// 购买课程
export const buyVideo = id => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/buy',
    data: {
      id
    }
  })
}
// 设置课程记录
export const sendLog = data => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/finish_log',
    data: {
      id: data.id
    }
  })
}
