<template>
  <div class="layout">
    <router-view></router-view>
    <van-tabbar v-model="active" active-color="#172325" inactive-color="#9B9FA2" route>
      <van-tabbar-item icon="wap-home-o" to="/index">首页</van-tabbar-item>
      <van-tabbar-item icon="orders-o" to="/learnLesson">学习</van-tabbar-item>
      <van-tabbar-item icon="label-o" to="/kaoshi">题库</van-tabbar-item>
      <van-tabbar-item icon="contact" to="/Mine">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: 'Layout',
  data() {
    return {
      active: 0
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.layout {
  height: 100%;
}
.van-tabbar {
  border-top: 1px solid #ccc;
}
</style>
