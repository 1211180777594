<template>
  <div class="video-item">
    <van-icon class="left_arrow" name="arrow-left" @click="$router.go(-1)" />
    <div class="video_box">
      <video class="video" :poster="videoInfo.cover" :src="videoUrl" controls id="video1"></video>
    </div>
    <!-- 头部区域 -->
    <div class="header">
      <div class="header_left">
        <div class="tag" v-text="videoInfo.catename"></div>
        <div class="certificate">
          <img src="../../assets/images/certificate.png" alt="" />
          <span>课程证书</span>
        </div>
      </div>
      <div class="header_right">
        <div class="title">{{ videoInfo.title | textOverflow(13) }}</div>
        <div class="row">
          <div class="teacher">{{ '讲师：' + videoInfo.teacher.name }}</div>
          <div class="time">时长：{{ videoInfo.duration }}</div>
        </div>
      </div>
      <img v-if="videoInfo.is_fav === 1" src="../../assets/images/start_o.png" alt="" class="start" @click="editVideoFav" />
      <img v-else-if="videoInfo.is_fav === 0" src="../../assets/images/start.png" alt="" class="start" @click="editVideoFav" />
    </div>
    <!-- 头部区域 -->

    <!-- tab栏导航区域 -->
    <van-tabs v-model="active" color="#FED30B" class="tabs_box" >
      <van-tab title="目录">
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item v-for="item in videoInfo.unit" :key="item.id" :title="item.unit_name" :name="item.id">
            <div v-for="childItem in item.course" :class="childItem.id === videoActiveId - 0 ? 'item active' : 'item'" :key="childItem.id" @click="switchVideo(childItem.id, childItem.play_status)">
              <img class="active_icn" src="../../assets/images/video_active.png" alt="" />
              <span>{{ childItem.title }}</span>
              <img class="lock_img" v-if="childItem.play_status === 0" src="../../assets/images/video_lock.png" alt="" />
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-tab>
      <van-tab title="详情" v-if="false">
        <div class="wrap">
          <div class="video_content" v-text="videoInfo.desc"></div>
          <div class="video_disc">教程简介：</div>
          <div class="content" v-html="videoInfo.content"></div>
        </div>
      </van-tab>
      <van-tab title="评论" v-if="false">
        <div class="pinglun_wrap">
          <div class="pinglun_box">
            <div class="item_pinglun" v-for="item in videoInfo.comments" :key="item.id">
              <div class="item_left">
                <van-image class="avatar" round :src="item.avatar" />
              </div>
              <div class="item_right">
                <div class="row">
                  <div class="title" v-text="item.nickname"></div>
                  <div class="time">{{ item.createtime_text }}</div>
                </div>
                <div class="content_pinglun" v-text="item.content"></div>
              </div>
            </div>
          </div>
          <van-field v-model="pingLunText" center clearable>
            <template #button>
              <van-button size="small" type="primary" @click="pingLun">发 送</van-button>
            </template>
          </van-field>
        </div>
      </van-tab>
    </van-tabs>
    <!-- tab栏导航区域 -->
  </div>
</template>
<script>
import { getVideoInfo, getVideoUrl, editVideoFav, postPingLun, setVideoFinish, buyVideo, sendLog } from '@/api/Index/VideoItem'
export default {
  name: 'VideoItem',
  props: {
    // 视频ID
    videoId: {
      type: String,
      required: true
    },
    // 播放中的视频ID
    activeVideoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // 控制视频播放事件次数
      flag: true,
      // 播放中的视频ID
      videoActiveId: null,
      // 视频链接
      videoUrl: null,
      // 记录播放完一个视频的时长
      finishTime: 0,
      // 默认激活的tab索引
      active: 0,
      // 折叠面板激活名称
      activeName: null,
      // 评价内容
      pingLunText: null,
      // 视频详情
      videoInfo: {
        teacher: {
          name: null
        }
      },
      interval: null,
      interval2: null
    }
  },
  mounted() {
    // 获取视频详情
    this.getVideoInfo()
  },
  destroyed() {
    clearInterval(this.interval)
    this.interval = null
    clearInterval(this.interval2)
    this.interval2 = null
  },
  methods: {
    // 获取视频详情
    async getVideoInfo() {
      const { data: res } = await getVideoInfo(this.videoId)
      this.videoInfo = res
      if (this.activeVideoId === '0') {
        this.getVideoUrl(res.last_content_id)
        this.videoActiveId = res.last_content_id
      } else {
        this.getVideoUrl(this.activeVideoId)
        this.videoActiveId = this.activeVideoId
      }
    },
    // 获取播放视频的地址
    async getVideoUrl(id) {
      const res = await getVideoUrl(id)
      if (res.code === 0) {
        // 微信支付
        this.weixinPay()
      }
      this.next_id = res.data.next_id
      // 设置视频播放地址
      this.videoUrl = res.data.PlayURL
      // 设置展开的面板
      this.activeName = res.data.unit_id
      // 获取视频播放进度
      this.getVideoTime()
    },
    // 收藏/取消收藏视频
    async editVideoFav() {
      const { data } = await editVideoFav(this.videoId)
      this.videoInfo.is_fav = data.is_fav
      this.$toast('操作成功')
    },
    // 发布评论
    async pingLun() {
      const res = await postPingLun({ course_id: this.videoId, content: this.pingLunText })
      if (res.code === 1) {
        this.$toast('发布成功')
        this.pingLunText = ''
        // 获取视频详情
        this.getVideoInfo()
      } else {
        this.$toast('发布失败')
      }
    },
    // 获取视频播放进度
    getVideoTime() {
      var video = document.getElementById('video1')
      video.onplay = () => {
        if (this.flag) {
          this.flag = false
          this.interval = setInterval(() => {
            // console.log(this.finishTime)
            this.finishTime += 1
            // 判断视频播放完成
            if (video.ended) {
              setVideoFinish({ id: this.videoActiveId, seconds: this.finishTime, duration: this.finishTime })
              setInterval(() => {
                location.reload()
              }, 1000)
              return clearInterval(this.interval)
            }
          }, 1000)
        }
        this.interval2 = setInterval(() => {
          sendLog({ id: this.videoActiveId })
          if (this.finishTime > 100000) {
            return clearInterval(this.interval2)
          }
        }, 10000)
      }
    },
    // 切换视频
    switchVideo(id, isGet) {
      if (isGet === 0) {
        return this.$toast('请观看上章视频')
      }
      this.$router.push(`/VideoItem/${this.videoId}/${id}`)
      location.reload()
    },
    // 微信支付
    async weixinPay() {
      const { data } = await buyVideo(this.videoId)
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: data.appId, // 公众号id
          timeStamp: data.timeStamp, // 当前的时间戳
          nonceStr: data.nonceStr, // 支付签名随机字符串
          package: data.package, // 订单详情扩展字符串，prepay_id=****
          signType: data.signType, // 签名类型，默认为MD5
          paySign: data.paySign // 支付签名
        },
        res2 => {
          if (res2.err_msg === 'get_brand_wcpay_request:ok') {
            console.log(res2)
          } else if (res2.err_msg === 'get_brand_wcpay_request:fail') {
            this.$toast.fail('支付失败')
          } else if (res2.err_msg === 'get_brand_wcpay_request:cancel') {
            this.$toast.fail('支付失败')
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.video-item {
  .left_arrow {
    position: absolute;
    color: #fff;
    top: 20px;
    left: 10px;
    z-index: 999;
  }
  position: relative;
  .video_box {
    .video {
      width: 100%;
      height: 424px;
      background: #000;
    }
  }
  // 头部
  .header {
    position: relative;
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    .start {
      position: absolute;
      top: 20px;
      right: 50px;
      width: 28px;
      height: 28px;
    }
    .header_left {
      text-align: center;
      .tag {
        padding: 0 10px;
        height: 36px;
        line-height: 36px;
        background: #eef2fb;
        border-radius: 18px;
        font-size: 18px;
        font-weight: bold;
        color: #4c6fcb;
      }
      .certificate {
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin-top: 22px;
        height: 31px;
        line-height: 31px;
        border: 1px solid #eb843a;
        border-radius: 16px;
        font-size: 16px;
        font-weight: bold;
        color: #eb843a;
        img {
          margin-right: 6px;
          margin-left: 10px;
          width: 13px;
          height: 16px;
        }
      }
    }
    .header_right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
      .row {
        display: flex;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        .time {
          margin-left: 41px;
        }
      }
    }
  }
  // tab栏
  /deep/ .tabs_box {
    .van-tabs__wrap {
      margin-bottom: 10px;
    }
    .van-tabs__line {
      width: 57px;
      height: 10px;
    }
  }
  .item {
    margin-bottom: 50px;
    position: relative;
    padding-left: 60px;
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    &:last-child {
      margin-bottom: 0;
    }
    .active_icn {
      display: none;
      position: absolute;
      left: 20px;
      top: 10px;
      width: 23px;
      height: 19px;
    }
    .lock_img {
      margin-left: 10px;
      width: 20px;
      height: 25px;
    }
  }
  .active {
    color: #d43c22;
    .active_icn {
      display: block;
    }
  }
  // 详情
  .wrap {
    margin: 0 auto;
    width: 710px;
    padding-top: 20px;
    .video_content {
      width: 710px;
      height: 264px;
      background: linear-gradient(90deg, #fe8b4c, #fedb97);
      border-radius: 30px;
      box-sizing: border-box;
      padding-top: 30px;
      padding-left: 30px;
      font-size: 26px;
      font-weight: bold;
      color: #000000;
    }
    .video_disc {
      margin-top: 50px;
      font-size: 30px;
      font-weight: 400;
      color: #000000;
      line-height: 41px;
    }
    .content {
      font-size: 28px;
      overflow: hidden;
      font-weight: 400;
      color: #666666;
    }
  }
  // 评论
  .pinglun_wrap {
    position: relative;
    .pinglun_box {
      padding-bottom: 60px;
      .item_pinglun {
        padding-top: 20px;
        padding-bottom: 35px;
        border-bottom: 1px solid #f9f9f9;
        display: flex;
        &:last-child {
          padding-bottom: 62px;
        }
        .item_left {
          padding-left: 36px;
          .avatar {
            width: 74px;
            height: 74px;
          }
        }
        .item_right {
          padding-right: 22px;
          margin-left: 40px;
          flex: 1;
          .row {
            display: flex;
            .title {
              font-size: 26px;
              font-weight: 400;
              color: #333333;
              line-height: 40px;
            }
            .time {
              margin-left: auto;
              font-size: 20px;
              font-weight: 400;
              color: #999999;
            }
          }
          .content_pinglun {
            margin-top: 35px;
            font-size: 26px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
    /deep/ .van-field {
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 710px;
      height: 62px;
      background: #ffffff;
      border: 1px solid #a0a0a0;
      border-radius: 30px;
      .van-cell__value {
        position: static;
      }
      .van-field__button {
        position: absolute;
        right: 0;
        width: 124px;
        height: 62px;
        border-radius: 30px;
      }
      button {
        border: 0;
        background: #fed30b;
        border-left: 1px solid #a0a0a0;
        width: 100%;
      }
    }
  }
}
</style>
