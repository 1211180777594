import request from '@/utils/request.js'
// 获取视频分类
export const getVideoCate = () => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/categories',
    data: {
      type: 'video'
    }
  })
}
// 获取视频列表
export const getVideoList = data => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/index',
    data: {
      type: 'video',
      cid: data.cid,
      page: data.page,
      limit: data.limit
    }
  })
}
// 获取课程分类名称
export const getVideoCateName = cid => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/get_catename',
    data: {
      cid
    }
  })
}
