import request from '@/utils/request.js'
// 发送code
export const postUserCode = code => {
  return request({
    method: 'GET',
    url: '/api/yalimedia.user/authUser',
    params: {
      code
    }
  })
}

// 获取轮播图
export const getBannerList = () => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.ad/index',
    data: {
      type: 'banner',
      limit: 7
    }
  })
}
// 获取首页分类
export const getIndexCate = () => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.menu/index',
    data: {
      page: 1,
      limit: 10
    }
  })
}
// 获取广告
export const getAdv = () => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.ad/index',
    data: {
      type: 'index',
      limit: 1
    }
  })
}
// 获取课程推荐分类
export const getVideoRecommend = () => {
  return request({
    method: 'POST',
    url: '/api/index/index'
  })
}
// 获取banner推荐课程
export const getBannerVideoList = tag => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/hot',
    data: {
      type: 'video',
      tag: tag,
      page: 1,
      limit: 10
    }
  })
}
// 获取公告
export const getNewsList = limit => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.news/index',
    data: {
      limit
    }
  })
}
