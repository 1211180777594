<template>
  <div class="video_recommend">
    <div class="title" v-text="title"></div>
    <div class="box">
      <router-link :to="'/VideoItem/' + item.id + '/0'" class="item" v-for="item in list" :key="item.id">
        <img class="cover" :src="item.cover" alt="" v-lazy="item.cover" />
        <span>{{ item.title | textOverflow(15) }}</span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VideoRecommend',
  props: {
    // 分类名称
    title: {
      type: String,
      required: true
    },
    // 分类数据列表
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.video_recommend {
  display: block;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      width: 33%;
      padding-bottom: 30px;
      .cover {
        width: 100%;
        height: 120px;
      }
      span {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #999999;
      }
    }
  }
}
</style>
