import request from '@/utils/request.js'
// 获取视频列表
export const getVideoList = keyword => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.course/index',
    data: {
      type: 'video',
      page: 1,
      limit: 200,
      keyword
    }
  })
}
