<template>
  <div class="video-cate">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="视频课程" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <div class="wrap">
      <!-- 开通VIP区域 -->
      <open-vip></open-vip>
      <!-- 开通VIP区域 -->

      <!-- 课程分类区域 -->
      <div class="cate_box">
        <div class="title">课程分类</div>
        <div class="input_box" @click="showVideoCatePopup = true">
          <input type="text" v-model="value" readonly />
          <img class="icon" src="../../assets/images/select.png" alt="" />
        </div>
      </div>
      <!-- 课程分类区域 -->

      <!-- 视频列表区域 -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div v-for="item in videoList" :key="item.id">
          <course-item :classInfo="item"></course-item>
        </div>
      </van-list>
      <!-- 视频列表区域 -->

      <!-- 课程分类弹出层 -->
      <van-popup v-model="showVideoCatePopup" position="bottom" :style="{ height: '50%' }">
        <van-picker title="课程分类" show-toolbar :columns="videoCateList" @confirm="onConfirm" @cancel="showVideoCatePopup = false" value-key="name" ref="picker" />
      </van-popup>
      <!-- 课程分类弹出层 -->
    </div>
  </div>
</template>
<script>
import OpenVip from '@/components/open-vip.vue'
import courseItem from '@/components/course-item.vue'
import { getVideoCate, getVideoList, getVideoCateName } from '@/api/Index/VideoCate'
export default {
  name: 'VideoCate',
  components: { OpenVip, courseItem },
  props: {
    // 视频分类ID
    videoCateId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // 控制视频分类弹出层显示隐藏
      showVideoCatePopup: false,
      // 视频分类选中的名称
      value: null,
      // 视频分类列表
      videoCateList: [],
      // 视频列表
      videoList: [],
      // 页码数
      pageNum: 1,
      // 总数
      total: 0,
      // 每页条数
      limit: 10,
      // 控制列表加载状态
      loading: false,
      // 控制是否加载完成
      finished: false
    }
  },
  mounted() {
    // 获取视频分类
    this.getVideoCate()
    // 获取视频分类名称
    this.getVideoCateName()
  },
  methods: {
    // 获取视频分类
    async getVideoCate() {
      const { data } = await getVideoCate()
      this.videoCateList = data
    },
    // 获取视频列表
    async onLoad(id = this.videoCateId) {
      // 获取数据
      const { data: res } = await getVideoList({ cid: id, page: this.pageNum, limit: this.limit })
      this.total = res.total
      this.videoList = [...this.videoList, ...res.data]
      this.pageNum += 1
      this.loading = false
      // 数据获取完毕
      if (this.pageNum * this.limit >= this.total) {
        this.finished = true
      }
    },
    // 获取视频分类名称
    async getVideoCateName() {
      const { data: res } = await getVideoCateName(this.videoCateId)
      this.value = res.name
    },
    // 视频分类确定事件
    onConfirm() {
      const selectItem = this.$refs.picker.getValues()[1]
      this.videoList = []
      this.pageNum = 1
      this.value = selectItem.name
      this.onLoad(selectItem.id)
      this.showVideoCatePopup = false
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 710px;
  margin: 0 auto;
  // 课程分类
  .cate_box {
    margin-top: 22px;
    display: flex;
    height: 50px;
    align-items: center;
    .title {
      width: 130px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      margin-right: 10px;
    }
    .input_box {
      flex: 1;
      position: relative;
      height: 50px;
      border-radius: 25px;
      display: flex;
      input {
        width: 100%;
        border: 0;
        height: 50px;
        line-height: 50px;
        background: #ffdcab;
        border-radius: 25px;
        color: #333333;
        text-align: center;
        font-size: 30px;
      }
      .icon {
        width: 26px;
        height: 16px;
        position: absolute;
        right: 20px;
        top: 17px;
      }
    }
  }
}
</style>
